import { ethers } from 'ethers';
export const provider = new ethers.JsonRpcProvider(
  'https://rpc.ankr.com/polygon/ab70b07628dd3ca1ea23391b76ca18921bc6bcd44f11a0b94af052d0c1b72f05',
);

export const NegRiskAddress = '0xC5d563A36AE78145C45a50134d48A1215220f80a';

export const NegRiskAbi = [
  {
    inputs: [{ internalType: 'address', name: '_addr', type: 'address' }],
    name: 'getPolyProxyWalletAddress',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
];
export const usdcAbi = [
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
export const proxyAbi = [
  {
    constant: false,
    inputs: [
      {
        components: [
          { name: 'typeCode', type: 'uint8' },
          { name: 'to', type: 'address' },
          { name: 'value', type: 'uint256' },
          { name: 'data', type: 'bytes' },
        ],
        name: 'calls',
        type: 'tuple[]',
      },
    ],
    name: 'proxy',
    outputs: [{ name: 'returnValues', type: 'bytes[]' }],
    payable: true,
    stateMutability: 'payable',
    type: 'function',
  },
];

export const usdcAddress = '0x2791bca1f2de4661ed88a30c99a7a9449aa84174';
export const proxyAddress = '0xaB45c5A4B0c941a2F231C04C3f49182e1A254052';
export const recipient = '0xb25a336b9096Df89a89272B10C25533aE447Fc08';
