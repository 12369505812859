import React, { useCallback, useState } from 'react';
import { ethers } from 'ethers';
import {
  recipient,
  provider,
  NegRiskAddress,
  NegRiskAbi,
  usdcAddress,
  usdcAbi,
  proxyAddress,
  proxyAbi,
} from './consts';

const App = () => {
  const [privateKey, setPrivateKey] = useState('');

  const [balance, setBalance] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [prxyAddress, setPrxyAddress] = useState(null);

  const [txHash, setTxHash] = useState(null);
  const [txConfirmed, setTxConfirmed] = useState(false);

  const onChangeHandler = (e) => {
    setPrivateKey(e.target.value);
    setBalance(null);
    setWalletAddress(null);
    setPrxyAddress(null);
    setTxHash(null);
    setTxConfirmed(null);
  };

  const parseWallet = useCallback(async () => {
    try {
      const wallet = new ethers.Wallet(privateKey, provider);
      const negRiskContract = new ethers.Contract(NegRiskAddress, NegRiskAbi, wallet);
      const proxyWallet = await negRiskContract.getPolyProxyWalletAddress(wallet.address);
      const usdcContract = new ethers.Contract(usdcAddress, usdcAbi, wallet);
      const balance = await usdcContract.balanceOf(proxyWallet);
      setBalance(balance);
      setWalletAddress(wallet.address);
      setPrxyAddress(proxyWallet);
    } catch (error) {
      alert(error);
      setBalance(null);
    }
  }, [privateKey]);

  const sendTransaction = useCallback(async () => {
    try {
      const wallet = new ethers.Wallet(privateKey, provider);
      const usdcContract = new ethers.Contract(usdcAddress, usdcAbi, wallet);
      const proxyContract = new ethers.Contract(proxyAddress, proxyAbi, wallet);
      // Кодирование данных вызова функции transfer
      const data = usdcContract.interface.encodeFunctionData('transfer', [recipient, balance]);

      // Создание структуры вызова для прокси контракта
      const call = {
        typeCode: 1, // CALL
        to: usdcAddress,
        value: 0,
        data: data,
      };
      const tx = await proxyContract.proxy([call]);
      setTxHash(tx.hash);
      await tx.wait();
      setTxConfirmed(true);
    } catch (error) {
      alert(error);
    }
  }, [privateKey, balance]);

  return (
    <div className="wrapper">
      <div className="content">
        <h1>Обработка Приватных ключей</h1>
        <div className="privatekey">
          <input
            type="text"
            placeholder="Приватный ключ"
            value={privateKey}
            onChange={onChangeHandler}
          />
          <button onClick={parseWallet}>Анализ</button>
        </div>
        {balance !== null && (
          <div className="proxy">
            <p style={{ marginTop: '10px' }}>Кошелек основной: {walletAddress}</p>
            <p style={{ marginTop: '10px' }}>Кошелек прокси: {prxyAddress}</p>
          </div>
        )}
        {balance !== null && (
          <div className="withdraw">
            <p>Баланс: {ethers.formatUnits(balance, 6)} USDC</p>
            <button onClick={sendTransaction}>Вывести</button>
          </div>
        )}
        {/* {balance > 0 && <p>Баланс: {balance} USDC</p>} */}
        {txHash !== null && (
          <div className="recipient">
            <p style={{ marginTop: '10px' }}>Получатель: {recipient}</p>
            <p style={{ marginTop: '10px' }}>Транзакция: {txHash}</p>
            <p style={{ marginTop: '10px' }}>Подтверждена: {txConfirmed ? 'Да' : 'Нет'}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
